import axios from "axios";
import store from "@/store/index";
import { message, Modal } from "ant-design-vue";

const service = axios.create({
  baseURL: process.env.VUE_APP_XMDWS_BASEURL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "D-App-Key": process.env.VUE_APP_KEY,
    "D-App-Version-Type": process.env.VUE_APP_VERSION_TYPE,
    "D-App-Version-Number": process.env.VUE_APP_VERSION_NUMBER,
  },
});

service.interceptors.request.use(
  (config) => {
    message.loading("数据加载中...", 0);
    Object.assign(config.headers, {
      "D-User-Token": store.getters.getAccount.token,
    });
    return config;
  },
  (error) => {
    message.destroy();
    Modal.error({
      title: "温馨提示",
      content: error,
    });
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    message.destroy();
    if (response.status === 200) {
      if (response.data.status === "FAILURE") {
        Modal.error({
          title: "温馨提示",
          content: response.data.message,
        });
      }
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    message.destroy();
    Modal.error({
      title: "温馨提示",
      content: error,
    });
    return Promise.reject(error);
  }
);

export default service;
