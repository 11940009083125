import http from "@/api/http.js";

function listByAppId(appId) {
	return http({
		url: "/SysAppVersion/ListByAppId/" + appId,
		method: "get",
	});
}

function listByCurrent() {
	return http({
		url: "/SysAppVersion/ListByCurrent",
		method: "get",
		params: {
			AppKey: process.env.VUE_APP_KEY,
			Type: process.env.VUE_APP_VERSION_TYPE,
			Number: process.env.VUE_APP_VERSION_NUMBER,
		},
	});
}

function create(payload) {
	return http({
		url: "/SysAppVersion/Create",
		method: "post",
		data: payload,
	});
}

function updateStatus(id, status) {
	return http({
		url: "/SysAppVersion/Update",
		method: "post",
		data: {
			id: id,
			status: status,
		},
	});
}

function updateRequired(id, required) {
	return http({
		url: "/SysAppVersion/Update",
		method: "post",
		data: {
			id: id,
			required: required,
		},
	});
}

function uploadApkFile(payload) {
	return http({
		headers: {
			"Content-Type": "multipart/form-data",
		},
		url: "/SysAppVersion/UploadApkFile",
		method: "post",
		data: payload,
	});
}

function deleteById(id) {
	return http({
		url: "/SysAppVersion/DeleteById/" + id,
		method: "post",
	});
}

export default {
	listByAppId,
	listByCurrent,
	create,
	updateStatus,
	updateRequired,
	uploadApkFile,
	deleteById
};
