<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import SysAppVersionApi from "@/api/sys/sys-app-version.js";
import { message } from "ant-design-vue";

export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted: function () {
    console.log("App Name: " + process.env.VUE_APP_NAME);
    console.log("App Version Type: " + process.env.VUE_APP_VERSION_TYPE_CN);
    console.log("App Version Number: " + process.env.VUE_APP_VERSION_NUMBER);

    message.config({ maxCount: 3 });
    this.initScreenSpec();
    this.checkAppVersion();
  },
  methods: {
    initScreenSpec: function () {
      this.$store.commit("setScreenSpec", {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      });
      window.onresize = () => {
        return (() => {
          this.$store.commit("setScreenSpec", {
            width: document.body.clientWidth,
            height: document.body.clientHeight,
          });
        })();
      };
    },
    checkAppVersion: function () {
      SysAppVersionApi.listByCurrent().then((response) => {
        if (response.status === "SUCCESS") {
          if (response.body && response.body.length > 0) {
            this.$message.loading("资源更新中...", 0);
            setTimeout(() => {
              location.reload();
              this.$message.destroy();
            }, 3000);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
html {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
}

body {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
}

#app {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  overflow: hidden;
}
</style>
