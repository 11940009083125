import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import { message } from "ant-design-vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/",
    component: () => import("@/views/layout/index.vue"),
    children: [
      {
        path: "/homepage",
        name: "Homepage",
        component: () => import("@/views/homepage/index.vue"),
      },
      {
        path: "/error",
        name: "Error",
        component: () => import("@/views/error/index.vue"),
      },
      {
        path: "/sys-app",
        name: "SysApp",
        component: () => import("@/views/sys/sys-app/index.vue"),
      },
      {
        path: "/ums-organization",
        name: "UmsOrganization",
        component: () => import("@/views/ums/ums-organization/index.vue"),
      },
      {
        path: "/ums-staff",
        name: "UmsStaff",
        component: () => import("@/views/ums/ums-staff/index.vue"),
      },
      {
        path: "/ums-user",
        name: "UmsUser",
        component: () => import("@/views/ums/ums-user/index.vue"),
      },
      {
        path: "/pms-erp",
        name: "PmsErp",
        component: () => import("@/views/pms/pms-erp/index.vue"),
      },
      {
        path: "/pms-danone",
        name: "PmsDanone",
        component: () => import("@/views/pms/pms-danone/index.vue"),
      },
      {
        path: "/pms-meadjohnson",
        name: "PmsMeadjohnson",
        component: () => import("@/views/pms/pms-meadjohnson/index.vue"),
      },
      {
        path: "/hms-checkin-staff",
        name: "HmsCheckinStaff",
        component: () => import("@/views/hms/hms-checkin-staff/index.vue"),
      },
      {
        path: "/hms-checkin-tenant",
        name: "HmsCheckinTenant",
        component: () => import("@/views/hms/hms-checkin-tenant/index.vue"),
      },
      {
        path: "/hms-checkin-visitor",
        name: "HmsCheckinVisitor",
        component: () => import("@/views/hms/hms-checkin-visitor/index.vue"),
      },
      {
        path: "/hms-disinfection",
        name: "HmsDisinfection",
        component: () => import("@/views/hms/hms-disinfection/index.vue"),
      },
      {
        path: "/hms-nucleic-acid",
        name: "HmsNucleicAcid",
        component: () => import("@/views/hms/hms-nucleic-acid/index.vue"),
      },
      {
        path: "/dms-activity",
        name: "DmsActivity",
        component: () => import("@/views/dms/dms-activity/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // 初始化用户信息
  store.dispatch("initAccount");
  // store.dispatch("initErpList");
  // store.dispatch("initErpActive");

  // 不做拦截的页面
  const noFilterViews = [
    "Homepage",
    "Login",
    "CheckinUser",
    "CheckinTenant",
    "CheckinVisitor",
  ];
  if (noFilterViews.indexOf(to.name) !== -1) {
    next();
    return;
  }

  // 检查Authorization信息
  const account = store.getters.getAccount;
  if (!account.token || !account.expirationTime) {
    store.commit("removeAccount");
    next({ name: "Login" });
    return;
  }

  let exp = account.expirationTime;
  if (exp.length === 10) exp += "000";
  if (parseInt(exp) < new Date().getTime()) {
    store.commit("removeAccount");
    message.error("用户许可证已失效，请重新登录！");
    next({ name: "Login" });
    return;
  }

  next();
});

export default router;
