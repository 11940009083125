import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const prefix =
  process.env.VUE_APP_NAME +
  "-" +
  process.env.VUE_APP_VERSION_TYPE +
  "-" +
  process.env.VUE_APP_VERSION_NUMBER;
const accountStorageKey = prefix + "-ACCOUNT";
const organizationStorageKey = prefix + "-ORGANIZATION";
const erpListStorageKey = prefix + "-ERP-LIST";
const erpActiveStorageKey = prefix + "-ERP-ACTIVE";

export default new Vuex.Store({
  state: {
    screenSpec: {
      width: 0,
      height: 0,
    },
    account: {
      id: "",
      username: "",
      realName: "",
      token: "",
      expirationTime: 0,
    },
    organization: {
      number: "",
      name: "",
    },
    erpList: [],
    erpActive: {},
  },
  getters: {
    getScreenSpec: function (state) {
      return state.screenSpec;
    },
    getAccount: function (state) {
      return state.account;
    },
    getOrganization: function (state) {
      return state.organization;
    },
    getErpList: function (state) {
      return state.erpList;
    },
    getErpActive: function (state) {
      return state.erpActive;
    },
  },
  mutations: {
    setScreenSpec: function (state, payload) {
      state.screenSpec.width = parseInt(payload.width) || 0;
      state.screenSpec.height = parseInt(payload.height) || 0;
    },
    setAccount: function (state, payload) {
      for (const x in state.account) {
        state.account[x] = payload[x];
      }
      localStorage.setItem(accountStorageKey, JSON.stringify(state.account));
    },
    setOrganization: function (state, payload) {
      state.organization = {
        number: payload.organizationNumber || payload.number || "",
        name: payload.organizationName || payload.name || "",
      };
      sessionStorage.setItem(
        organizationStorageKey,
        JSON.stringify(state.organization)
      );
    },
    setErpList: function (state, payload) {
      state.erpList = payload;
      sessionStorage.setItem(erpListStorageKey, JSON.stringify(state.erpList));
    },
    setErpActive: function (state, payload) {
      state.erpActive = payload;
      sessionStorage.setItem(
        erpActiveStorageKey,
        JSON.stringify(state.erpActive)
      );
    },
    removeAccount: function (state) {
      for (const x in state.account) {
        if (x === "username") {
          state.account[x] = state.account.username;
        } else if (x === "expirationTime") {
          state.account[x] = 0;
        } else {
          state.account[x] = "";
        }
      }
      localStorage.setItem(accountStorageKey, JSON.stringify(state.account));
    },
  },
  actions: {
    initAccount: function (context) {
      const account = localStorage.getItem(accountStorageKey);
      if (account) context.commit("setAccount", JSON.parse(account));
    },
    initOrganization: function (context) {
      const organization = sessionStorage.getItem(organizationStorageKey);
      if (organization)
        context.commit("setOrganization", JSON.parse(organization));
    },
    initErpList: function (context) {
      const erpList = sessionStorage.getItem(erpListStorageKey);
      if (erpList) context.commit("setErpList", JSON.parse(erpList));
    },
    initErpActive: function (context) {
      const erpActive = sessionStorage.getItem(erpActiveStorageKey);
      if (erpActive) context.commit("setErpActive", JSON.parse(erpActive));
    },
  },
  modules: {},
});
